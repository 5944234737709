import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import Layout from 'components/Layout';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';

import { getGaviLayoutFlags } from 'utils/helpers';
import './ErrorPage.scss';

const ErrorPageTemplate: FC<PageContent.IErrorPage> = ({
  page,
  siteSettings,
  commonSettings,
  header,
  footer,
}) => {
  const [
    {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      title,
      description,
      error,
      menu,
      tags,
    },
  ] = page?.nodes;

  const { GAVIDESIGN, GAVIHEADER } = getGaviLayoutFlags(tags, ['GAVIDESIGN', 'GAVIHEADER']);

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      isGaviDesign={GAVIDESIGN}
      isGaviHeader={GAVIHEADER}
    >
      <Container fluid>
        <div className="error-page">
          <h1 className="error-page__title">
            {title.map(({ value }) => (
              <span key={value} className="error-page__title-line">
                {value}
              </span>
            ))}
          </h1>
          {description ? (
            <DangerouslySetInnerHtml className="error-page__description" html={description} />
          ) : null}
          {menu.length ? (
            <ul className="error-page__list">
              {menu.map(({ name, url }) => (
                <li key={name} className="error-page__list-item">
                  <Button variant="link" to={url}>
                    <span className="error-page__list-label">{name}</span>
                    <IconCustom icon="chevron-right" />
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          {error ? <div className="error-page__error">{error}</div> : null}
        </div>
      </Container>
    </Layout>
  );
};

export default ErrorPageTemplate;
